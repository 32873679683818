import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import SignInForm from "./style"
import { Images } from "~data"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../../../services/auth"

const schema = yup
  .object({
    email: yup
      .string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password length should be at least 6 characters"),
    rememberMe: yup.bool(),
  })
  .required()

export default function SignIn() {
  const [errorRequest, setErrorRequest] = useState(null)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  const onSubmit = data => {
    console.log(data)
    handleLogin(data)
    // handleLogin(data).catch(error => {
    //   setErrorRequest(error.message)
    // })
  }

  if (isLoggedIn()) {
    navigate(`/`)
  }

  return (
    <SignInForm backgroundColor="#f3f4f6">
      <Container className="position-static">
        <Row className="align-items-center justify-content-center position-static">
          <Col xs="12" className="col-xl-6 col-lg-5 position-static">
            <SignInForm.Image
              backgroundImage={Images.Account.Signin}
            ></SignInForm.Image>
          </Col>
          <Col
            xs="12"
            className="col-xxl-5 col-xl-6 col-lg-7 col-md-8 col-xs-10"
          >
            <SignInForm.Box>
              <SignInForm.Title as="h2">
                <SignInForm.IconButton to="/">
                  <i className="fas fa-arrow-left" />
                </SignInForm.IconButton>
                Inicia sesión
              </SignInForm.Title>
              <SignInForm.Text as="p">
                Enter your account details below to log in.
              </SignInForm.Text>
              {errorRequest && <p className="error-message">{errorRequest}</p>}
              <SignInForm.FromSection>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-floating">
                    <label htmlFor="floatinginput">Your Email</label>
                    <input
                      className="form-control"
                      placeholder="Your Email"
                      type="email"
                      {...register("email", { required: true })}
                    />
                    <p className="error-message">{errors.email?.message}</p>
                  </div>
                  <div className="form-floating">
                    <label htmlFor="floatinginput2">Your Password</label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Cotraseña"
                      {...register("password", { required: true })}
                    />
                    <p className="error-message">{errors.password?.message}</p>
                  </div>
                  <div className="form-check d-flex align-items-center mt-6 mb-3">
                    <input
                      className="form-check-input bg-white float-none mt-0 mb-0 me-3"
                      type="checkbox"
                      {...register("rememberMe", { required: true })}
                    />
                    <SignInForm.FormText htmlFor="flexCheckDefault">
                      Remember me
                    </SignInForm.FormText>
                  </div>
                  <SignInForm.FormButton className="btn-primary mt-2">
                    Entrar
                  </SignInForm.FormButton>
                </form>
                <div className="buttons mt-6">
                  <SignInForm.FormText>
                    Aún no tienes una cuenta?
                    <a
                      className="btn-link text-electric-violet-2 ms-2"
                      href="/account/sign-up"
                    >
                      Crea una gratis
                    </a>
                  </SignInForm.FormText>

                  <p className="error-message">{errors.rememberMe?.message}</p>
                </div>
              </SignInForm.FromSection>
            </SignInForm.Box>
          </Col>
        </Row>
      </Container>
    </SignInForm>
  )
}
